import * as React from 'react';

type LogoStyle = "light" | "dark" | "hidden";

interface IAppContext {
  setNavOpen: (open: boolean) => void;
  navOpen?: boolean;
  setLogoStyle: (style: LogoStyle) => void;
  logoStyle?: LogoStyle;
}

export const AppContext = React.createContext<IAppContext>({} as IAppContext);

export const AppContextProvider: React.FunctionComponent<React.PropsWithChildren<any>> = ({ children }) => {
  const [navOpen, setNavOpen] = React.useState(false);
  const [logoStyle, setLogoStyle] = React.useState<LogoStyle>("dark");

  return (
    <AppContext.Provider value={{ setNavOpen, navOpen, setLogoStyle, logoStyle }}>
      {children}     
    </AppContext.Provider>
  );
};
